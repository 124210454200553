<template>
  <div class="pt-layout" role="group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PtSurveyLayout',
  setup() {
    useMonitoring();
  }
};
</script>

<style lang="scss" scoped>
.pt-layout {
  background-color: $white;
  display: block;
}
</style>
